/* global rp$, window */

import './newsletter_signup.scss';
const utils = require('public/js/helpers/utils');

var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');

	/********************************************
	 * Load Javascript for the Banner Vpicker Module
	 * -----------------------------------------
	 * @section newsletter_signup_js
	 ********************************************/
	(function NewsletterSignupFormModule() {
		'use strict';

		$onReady({
			rp$, window,
			label: 'newsletter_signup',
			fn: function () {
				var $newsletterSignups, $newsletterSignupEle, $successEle, $errorsEle;

				$newsletterSignups = rp$('.newsletter-signup-module');

				rp$.each($newsletterSignups, function NewsletterSignupFormModule_Iterator(idx, newsletterSignupEle) {
					$newsletterSignupEle = rp$(newsletterSignupEle);
					$successEle = $newsletterSignupEle.find('.newsletter-signup-success');
					$errorsEle = $newsletterSignupEle.find('.form-errors');

					function newsletterSignupWorking() {
						$newsletterSignupEle.find('.newsletter-button').addClass('disabled');
					}
					function newsletterSignupReady() {
						$newsletterSignupEle.find('.newsletter-button').removeClass('disabled');
					}

					function newsletterSignupHandleErrors(errors) {
						var errorsEleOffset, windowHeight, windowTop;

						/* Fill errors
						.*-----------------------------------------*/
						$errorsEle.show().empty();
						for(var i = 0, j = errors.length; i < j; i++) {
							var errMsg = errors[i];
							$errorsEle.append('<p>'+ errMsg +'</p>');
						}

						/* If errors are out of view (above or below window pane), scroll to it
						.*-----------------------------------------*/
						errorsEleOffset = $errorsEle.offset();
						windowHeight = rp$(window).height();
						windowTop = rp$(window).scrollTop();
						if((errorsEleOffset.top < windowTop) || (errorsEleOffset.top > (windowTop + windowHeight))) {
							rp$('html, body').animate({
								scrollTop: $errorsEle.offset().top - 40
							});
						}
					}

					function newsletterSignupHandleSuccess() {
						$successEle.fadeIn();
						$newsletterSignupEle.find('.subscribe-email').val('');
						$errorsEle.hide().empty();
					}

					function newsletterSignupSubmit() {
						var form = {
							email: null,
						};

						/* Pull in data from the form
						.*-----------------------------------------*/
						form.email = $newsletterSignupEle.find('.subscribe-email').val();

						/* Perform Submission
						.*-----------------------------------------*/
						newsletterSignupWorking();
						window.rp_app.request({
							method: 'POST',
							url: '/ajax/newsletter-signup',
							dataType: 'json',
							data: form,
							success: function newsletterSignupSubmit_Success(/*response*/) {
								if(window.tracking.google.use_gtag_admind_analytics){ 
									delete form.csrf_name;
									delete form.csrf_value;
									utils.hashSensitiveInformation(form.email).then(function (hashedValue) {
										window.rp_app.track.track_ec_general('newsletter_sign_up', {
											data:hashedValue,
										});
									});
								}
								newsletterSignupHandleSuccess();
							},
							statusCode: {
								400: function(jqxhr) {
									newsletterSignupReady();
									var error_details;
									if(jqxhr.hasOwnProperty('responseText')) {
										try {
											error_details = JSON.parse(jqxhr.responseText);
										} catch(e) {
											error_details = {
												errors: [$newsletterSignupEle.data('error-400')]
											};
										}
									}
									newsletterSignupHandleErrors(error_details.errors);
								}
							},
							error: function newsletterSignupSubmit_Error(jqxhr/*, status, error*/) {
								if(typeof this.statusCode[jqxhr.status] === 'function') { return; } // StatusCode handled
								newsletterSignupReady();
								newsletterSignupHandleErrors([$newsletterSignupEle.data('error-500')]);
							}
						});
					}

					$newsletterSignupEle.on('click', '.newsletter-button', function (evt) {
						evt.preventDefault();
						if(rp$(this).hasClass('disabled')) {
							return;
						}

						newsletterSignupSubmit();
					});
				});
			},
		});

	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
